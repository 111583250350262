<template>
	<div id="toolbar" class="b-0 l-0 w-full h-48 p-8 bg-black bg-opacity-25 backdrop-blur-sm flex items-center flex-row justify-between">
		<div id="left" class="flex gap-4">
			<Colors/>
			<div class="w-1 bg-white bg-opacity-50 rounded-lg"></div>
			<Tools/>
		</div>
		<div id="center" class="flex">
			<Frames/>
		</div>
		<div id="right" class="flex">
			<Settings/>
		</div>
	</div>
</template>

<script lang="ts">
import Colors from './Colors.vue';
import Frames from './Frames.vue';
import Tools from './Tools.vue';
import Settings from './Settings.vue';

export default {
	name: 'ToolbarElement',
	components: {
		Colors,
		Tools,
		Frames,
		Settings
	}
}
</script>

<style scoped>
#toolbar {
	position: fixed;
	bottom: 0;
	left: 0;
	width: 100%;
}
</style>
