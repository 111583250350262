<template>
	<div
		class="w-8 h-8 cursor-pointer"
		@pointerdown=createColorPicker
	></div>
</template>

<script lang="js">
import { setColor } from '@/pen';

export default {
	name: 'ColorItemCustom',
	methods: {
		createColorPicker(ev) {
			const tempColorInput = document.createElement('input');
			document.body.appendChild(tempColorInput);
			tempColorInput.style.display = 'none';
			tempColorInput.type = 'color';
			tempColorInput.click();
			document.body.removeChild(tempColorInput);
			
			tempColorInput.addEventListener('change', () => {
				const color = tempColorInput.value;
				this.$emit('add-color', color);
				setColor(ev.button === 0, color);
			});
		}
	}
}
</script>

<style scoped>
div {
	background-image: url('../assets/images/customcolor.png');
	background-size: cover;
}
</style>
