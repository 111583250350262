import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createBlock as _createBlock, createElementVNode as _createElementVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-0474a143"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "flex" }
const _hoisted_2 = { class: "tools flex flex-col" }
const _hoisted_3 = { class: "sizes" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ToolType = _resolveComponent("ToolType")!
  const _component_ToolSize = _resolveComponent("ToolSize")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($data.toolTypes, (toolType) => {
        return (_openBlock(), _createBlock(_component_ToolType, {
          type: toolType,
          key: toolType,
          isSelected: $data.stringToTool[toolType] === $data.currentType,
          onSetPenTool: ($event: any) => ($options.setPenTool(toolType))
        }, null, 8, ["type", "isSelected", "onSetPenTool"]))
      }), 128))
    ]),
    _createElementVNode("div", _hoisted_3, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($data.toolSizes, (toolSize) => {
        return (_openBlock(), _createBlock(_component_ToolSize, {
          size: toolSize,
          key: toolSize,
          isSelected: toolSize === $data.currentSize,
          onSetPenSize: ($event: any) => ($options.setPenSize(toolSize))
        }, null, 8, ["size", "isSelected", "onSetPenSize"]))
      }), 128))
    ])
  ]))
}