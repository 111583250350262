import { openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-8d52c2f0"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  id: "canvas",
  width: "300",
  height: "300",
  class: "fixed bg-white origin-top-left"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("canvas", _hoisted_1))
}