export enum PenType {
	Eraser,
	Brush,
	Fill,
	Eyedropper
}

interface IPenSettings {
	type: PenType;
	size: number;
}
export const penSettings: IPenSettings = {
	type: PenType.Brush,
	size: 2,
}

export const colors = {
	primary: '#000000',
	secondary: '#FF0000'
}

export function setColor(primary: boolean, color: string): void {
	const key = primary ? 'primary' : 'secondary';

	colors[key] = color;

	const colorElement = document.getElementById(`${key}Color`) as HTMLElement;
	colorElement.style.backgroundColor = color;
}

export function setPenType(newPenType: PenType): void {
	penSettings.type = newPenType;
}

export function setPenSize(newPenSize: number): void {
	penSettings.size = newPenSize;
}
