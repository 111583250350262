<template>
	<div :style="`background-color: ${color}`"
		class="w-8 h-8 relative cursor-pointer"
		@pointerdown=changeColor>
	</div>
</template>

<script lang="js">
import { setColor } from '@/pen';

export default {
	name: 'ColorItem',
	props: {
		color: String,
	},
	methods: {
		changeColor(ev) {
			// Middle click removes the color
			if (ev.button === 1) {
				this.$emit('delete-color', this.color);
				return;
			}
			
			if (this.color === undefined) return;
			
			setColor(ev.button === 0, this.color);
		}
	}
}
</script>

<style scoped>

</style>
