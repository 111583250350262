import { frames } from './frames';

export function exportToGif(): void {
	const apiUrl = 'https://warze.org/gifunvue/uploadgif';
	const frameDuration = 50;
	const gifName = 'Mario Running';

	// Convert canvas to Blob
	const canvasToBlob = async () => {
        // Create a FormData object to store all blobs
        const formData = new FormData();
        formData.append('frameDuration', frameDuration.toString());
        formData.append('gifName', gifName);

        // Convert each frame to Blob and append to FormData
        await Promise.all(frames.map(async (frame, index) => {
            const blob = await new Promise(resolve => frame.toBlob(resolve, 'image/png'));
            formData.append('images', blob as Blob, `frame_${index}.png`);
        }));

        // Upload the FormData to the API
        uploadToApi(formData);
    };

	const uploadToApi = async (formData: FormData) => {
        // Create a FormData object to send the Blob to the API
		formData.append('frameDuration', frameDuration.toString());
		formData.append('gifName', gifName);

        try {
            const response = await fetch(apiUrl, {
                method: 'POST',
                body: formData,
            });

            console.log(response);
        } catch (error) {
            console.error('Error during image upload:', error);
        }
    };

	canvasToBlob();
}

(window as any).exportToGif = exportToGif;
