<template>
	<div>
		<p class="text-center p-3 text-2xl">
			{{ frameCounter }}
		</p>

		<div class="flex flex-row gap-2">
			<button
				@click="goToPreviousFrame"
				class="cursor-pointer hover:opacity-50"
			>
				<svg width="32" height="32" viewBox="0 0 2 2">
					<path d="M0,1 2,2 2,0" fill="currentColor" />
				</svg>
			</button>
	
			<button
				@click="goToNextFrame"
				class="cursor-pointer hover:opacity-50"
			>	
				<svg width="32" height="32" viewBox="0 0 2 2">
					<path d="M2,1 0,2 0,0" fill="currentColor" />
				</svg>
			</button>
		</div>
	</div>
</template>

<script lang="ts">
import { ref } from 'vue';
import { currentFrame, frames, nextFrame, previousFrame } from '../frames';

const frameCount = ref(frames.length);
const currentFrameNumber = ref(currentFrame + 1);

export default {
	name: 'FramesElement',
	data() {
		return {
			frames,
			previousFrame,
			nextFrame
		}
	},
	methods: {
		goToPreviousFrame() {
			previousFrame();
			currentFrameNumber.value = currentFrame + 1;
		},
		goToNextFrame() {
			nextFrame();
			frameCount.value = frames.length;
			currentFrameNumber.value = currentFrame + 1;
		}
	},
	computed: {
		frameCounter() {
			return `${currentFrameNumber.value}/${frameCount.value}`;
		}
	},
}
</script>

<style scoped>

</style>
