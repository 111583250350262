import { setCursor } from './mouse';

const keysPressed = new Set();

document.body.addEventListener('keydown', (ev: KeyboardEvent) => {
	keysPressed.add(ev.key);

	switch (ev.key) {
		case ' ':
			setCursor('move');
			break;
	}
});

document.body.addEventListener('keyup', (ev: KeyboardEvent) => {
	keysPressed.delete(ev.key);

	switch (ev.key) {
		case ' ':
			setCursor('default');
			break;
	}
});

export function isKeyPressed(button: string): boolean {
	return keysPressed.has(button);
}
