import { resolveComponent as _resolveComponent, createVNode as _createVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_FrameCanvas = _resolveComponent("FrameCanvas")!
  const _component_Toolbar = _resolveComponent("Toolbar")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_FrameCanvas),
    _createVNode(_component_Toolbar)
  ], 64))
}