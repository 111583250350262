import { setCanvasOffset, canvasSettings } from './settings';

export function getWindowCenter(): {x: number, y: number} {
	return {
		x: window.innerWidth / 2,
		y: window.innerHeight / 2
	}
}

window.addEventListener('resize', resize);

function resize() {
	const { x, y } = getWindowCenter();
	const { width, height } = canvasSettings;

	setCanvasOffset(x - width / 2, y - height / 2);
}

window.onload = resize;
