export const canvasSettings = {
	xOffset: 0,
	yOffset: 0,
	width: 300,
	height: 300,
	zoom: 1,
	backgroundColor: '#FFFFFF'
}

export function setCanvasOffset(xOffset: number, yOffset: number): void {
	canvasSettings.xOffset = xOffset;
	canvasSettings.yOffset = yOffset;

	updateCanvasProportions();
}

export function applyCanvasOffset(xOffset: number, yOffset: number): void {
	canvasSettings.xOffset += xOffset;
	canvasSettings.yOffset += yOffset;

	updateCanvasProportions();
}

export function changeCanvasZoom(multiplier: number): void {
	canvasSettings.zoom *= multiplier;
}

export function setCanvasSize(width: number, height: number, frames: Array<HTMLCanvasElement>): void {
	const currentCanvas = document.getElementById('canvas') as HTMLCanvasElement;

	[currentCanvas, ...frames].forEach((cvs) => {
		const ctx = cvs.getContext('2d') as CanvasRenderingContext2D;
	
		const tempCvs = document.createElement('canvas');
		tempCvs.width = cvs.width;
		tempCvs.height = cvs.height;
		
		const tempCtx = tempCvs.getContext('2d') as CanvasRenderingContext2D;
	
		tempCtx.drawImage(cvs, 0, 0);
	
		canvasSettings.width = width;
		canvasSettings.height = height;
	
		cvs.width = width;
		cvs.height = height;
	
		ctx.drawImage(tempCvs, 0, 0);
	})

	updateCanvasProportions();
}

export function updateCanvasProportions(): void {
	const cvs = document.getElementById('canvas') as HTMLElement;

	cvs.style.transform = `translate(
		${canvasSettings.xOffset}px,
		${canvasSettings.yOffset}px
	) scale(${canvasSettings.zoom})`;
}
