<template>
	<div class="flex flex-col">
		<p>
			<span class="py-2">Width:</span>
			<input @input="update" type="number" max="500" v-model="width">
		</p>
		<input @input="update" type="range" min="50" max="500" v-model="width">
		<br>
		<p>
			<span class="py-2">Height:</span>
			<input @input="update" type="number" v-model="height">
		</p>
		<input @input="update" type="range" min="50" max="500" v-model="height">
	</div>
</template>

<script lang="js">
import { setCanvasSize } from '@/settings';
import { frames } from '@/frames';

export default {
	name: 'SettingsElement',
	data() {
		return {
			width: 300,
			height: 300
		}
	},
	methods: {
		update() {
			setCanvasSize(this.width, this.height, frames);
		},
	}
}
</script>

<style scoped>
input[type=number] {
	width: 50px;
	background-color: transparent;
	margin-left: 10px;
}
</style>
