<template>
	<div class="w-32 h-32 relative">
		<div id="primaryColor"
			:style="{ backgroundColor: colors.primary }"
			@click="circleClick(true)"
			class="w-32 h-32 rounded-full border-white border-4 absolute top-0 left-0"></div>
		<div id="secondaryColor"
			:style="{ backgroundColor: colors.secondary }"
			@click="circleClick(false)"
			class="w-16 h-16 rounded-full border-white border-4 absolute bottom-0 right-0"></div>
	</div>
	<div class="h-32 rounded-lg overflow-hidden">
		<div
			class="h-32 flex flex-col flex-wrap items-start"
			:style="{ width: dynamicWidth }"
		>
			<ColorItem v-for="color in allColors"
				:color="color"
				:key="color"
				@delete-color="deleteColor"
			/>
			<ColorItemCustom 
				@add-color="addColor"
			/>
		</div>
	</div>
</template>

<script lang="js">
import { colors, setColor } from '../pen';
import ColorItem from './ColorItem.vue';
import ColorItemCustom from './ColorItemCustom.vue';

export default {
	name: 'ColorsElement',
	components: {
		ColorItem,
		ColorItemCustom
	},
	data() {
		return {
			colors,
			allColors: [
				'#FFFFFF',
				'#BBBBBB',
				'#666666',
				'#000000',
				'#FF0000',
				'#FFDD00',
				'#00FF00',
				'#3333FF',
				'#884400',
				'#FF8800',
				'#FF00FF'
			],
		}
	},
	methods: {
		addColor(color) {
			this.allColors.push(color);
		},
		deleteColor(color) {
			this.allColors = this.allColors.filter(c => c !== color);
		},
		circleClick(leftClick) {
			const tempColorInput = document.createElement('input');
			document.body.appendChild(tempColorInput);
			tempColorInput.style.display = 'none';
			tempColorInput.type = 'color';
			tempColorInput.click();
			document.body.removeChild(tempColorInput);
			
			tempColorInput.addEventListener('change', () => {
				const color = tempColorInput.value;
				this.$emit('add-color', color);
				setColor(leftClick, color);
				this.addColor(color);
			});
		}
	},
	computed: {
		dynamicWidth() {
			const totalColors = this.allColors.length + 1;
			const colorColumns = Math.ceil(totalColors / 4);
			return `${colorColumns * 32}px`;
		}
	}
}
</script>

<style scoped>

</style>
