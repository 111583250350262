<template>
	<canvas id="canvas" width="300" height="300" class="fixed bg-white origin-top-left"></canvas>
</template>

<script lang="ts">
import { isMouseHeld } from '../mouse';
import { isKeyPressed } from '../keyboard';
import { colors, PenType, penSettings } from '../pen';
import { canvasSettings, applyCanvasOffset } from '../settings';
import { copyToSavedFrame, currentFrame } from '../frames';

let lastPosition: {x: number, y: number} | null = null;

export default {
	name: 'FrameCanvas',
}

document.body.addEventListener('mousemove', (ev: MouseEvent) => {
	const {
		clientX,
		clientY,
		movementX,
		movementY
	} = ev;
	const cvs = document.getElementById('canvas') as HTMLCanvasElement;

	if (isMouseHeld(0) || isMouseHeld(2)) {
		if (isKeyPressed(' ')) {
			applyCanvasOffset(movementX, movementY);

			return;
		}

		const absolutePos = {
			x: (clientX - canvasSettings.xOffset) / canvasSettings.zoom,
			y: (clientY - canvasSettings.yOffset) / canvasSettings.zoom
		};
		
		if (lastPosition !== null) {
			if (penSettings.type === PenType.Eyedropper) {
				// Get color from canvas


				return;
			} else if (penSettings.type === PenType.Fill) {
				


				return;
			}


			const ctx = cvs.getContext('2d') as CanvasRenderingContext2D;
			ctx.beginPath();
			ctx.strokeStyle = isMouseHeld(0) ? colors.primary : colors.secondary;
			ctx.lineWidth = penSettings.size;
			ctx.lineCap = 'round';
			switch (penSettings.type) {
				case PenType.Eraser:
					ctx.lineWidth = ctx.lineWidth * 2;
					ctx.strokeStyle = canvasSettings.backgroundColor;
					break;
			}
			ctx.moveTo(lastPosition.x, lastPosition.y);
			ctx.lineTo(absolutePos.x, absolutePos.y);
			ctx.stroke();

			copyToSavedFrame(currentFrame);
		}
		lastPosition = absolutePos;
	} else {
		lastPosition = null;
	}


});
</script> 

<style scoped>
#canvas {
	image-rendering: pixelated;
}
</style>
