<template>
	<div class="flex">
		<div class="tools flex flex-col">
			<ToolType
				v-for="toolType in toolTypes"
				:type="toolType"
				:key="toolType"
				:isSelected="stringToTool[toolType] === currentType"
				@setPenTool="setPenTool(toolType)"
			></ToolType>
		</div>
		<div class="sizes">
			<ToolSize
				v-for="toolSize in toolSizes"
				:size="toolSize"
				:key="toolSize"
				:isSelected="toolSize === currentSize"
				@setPenSize="setPenSize(toolSize)"
			></ToolSize>
		</div>
	</div>
</template>

<script lang="ts">
import { ref } from 'vue';
import { penSettings, setPenSize, setPenType, PenType } from '../pen';
import ToolSize from './ToolSize.vue';
import ToolType from './ToolType.vue';

const toolSizes = [2, 3, 4, 5, 6];
const toolTypes = ['brush', 'eraser', 'eyedropper', 'fill'];

const currentSize = ref(penSettings.size);
const currentType = ref(penSettings.type);

const stringToTool: Record<string, PenType> = {
	'brush': PenType.Brush,
	'eraser': PenType.Eraser,
	'eyedropper': PenType.Eyedropper,
	'fill': PenType.Fill,
}

export default {
	name: 'ToolsElement',
	components: {
		ToolSize,
		ToolType,
	},
	data() {
		return {
			toolSizes,
			currentSize,
			toolTypes,
			stringToTool,
			currentType
		}
	},
	methods: {
		setPenSize(size: number) {
			currentSize.value = size;
			setPenSize(size);
		},
		setPenTool(type: string) {
			const toolType = stringToTool[type];
			currentType.value = toolType;
			setPenType(toolType);
		}
	}
}
</script>

<style scoped>
.tool {
	cursor: pointer;

	svg {
		width: var(--tool-px);
		height: var(--tool-px);
	}

	&:hover {
		opacity: 0.5;
	}
}
.size {
	width: var(--size-px);
	height: var(--size-px);
	display: flex;
	justify-content: center;
	align-items: center;
	cursor: pointer;

	&:hover {
		opacity: 0.5;
	}
}
</style>
