<template>
	<div class="size"
		:class="{ selected: isSelected }"
		@pointerdown="setPenSize(size)"
	>
		<div
			:class="`rounded-full bg-white`"
			:style="elementSize"
		></div>
	</div>
</template>

<script lang="js">

export default {
	name: 'ToolSize',
	props: {
		size: {
			type: Number,
			required: true
		},
		isSelected: {
			type: Boolean,
			required: true
		}
	},
	methods: {
		setPenSize(size) {
			this.$emit('setPenSize', size);
		}
	},
	computed: {
		elementSize() {
			const multipliedSize = this.size * 4 + 'px';
			return {
				width: multipliedSize,
				height: multipliedSize
			};
		}
	}
}
</script>

<style scoped>
.selected {
	opacity: 0.5;
	pointer-events: none;
}
</style>
