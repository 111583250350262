<template>
	<FrameCanvas />
	<Toolbar />
</template>

<script lang="ts">
import FrameCanvas from './components/FrameCanvas.vue';
import Toolbar from './components/Toolbar.vue';

export default {
	name: 'App',
	components: {
		FrameCanvas,
		Toolbar,
	}
}
</script>

<style>
@font-face {
	font-family: Geist;
	src: url(./assets/fonts/GeistVF.woff2) format(woff2);
}
:root {
	--main: #232324;
	--main-text: #FFF;
	--tool-px: calc(128px / 4);
	--size-px: calc(128px / 5);
}
* {
	box-sizing: border-box;
}
body {
	background-image: url('./assets/images/grid.png');
	background-size: 64px 64px;
	background-color: var(--main);
	color: var(--main-text);
	height: 100vh;
	image-rendering: pixelated;
}
#app {
	font-family: Geist, Helvetica, Arial, sans-serif;
	font-weight: 100;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}
</style>
