import { canvasSettings } from './settings'; 

const frameOverview = document.createElement('div');
frameOverview.classList.add('fixed', 'top-0', 'left-0', 'flex', 'gap-2', 'flex-row', 'origin-top-left');
frameOverview.style.transform = 'scale(0.3)';
frameOverview.style.zIndex = '-1';

export let currentFrame = 0;

export const frames: Array<HTMLCanvasElement> = [createNewFrame()];

function createNewFrame(): HTMLCanvasElement {
	const newFrame = document.createElement('canvas');

	const { width, height } = canvasSettings;
	newFrame.width = width;
	newFrame.height = height;
	newFrame.classList.add('bg-white');
	
	document.body.appendChild(frameOverview);
	frameOverview.appendChild(newFrame);

	return newFrame;
}

export function addNewFrame() {
	frames.push(createNewFrame());
}

export function removeFrame(frame: number): void {
	frames.splice(frame, 1);
}


export function copyToSavedFrame(frame: number): void {
	const canvas = document.getElementById('canvas') as HTMLCanvasElement;
	copyFromCanvasToCanvas(canvas, frames[frame]);
}
function copyFromSavedFrame(frame: number): void {
	const canvas = document.getElementById('canvas') as HTMLCanvasElement;
	copyFromCanvasToCanvas(frames[frame], canvas);
}

function copyFromCanvasToCanvas(srcCanvas: HTMLCanvasElement, destCanvas: HTMLCanvasElement): void {
	const destCtx = destCanvas.getContext('2d') as CanvasRenderingContext2D;

	const { width, height } = srcCanvas;
	destCanvas.width = width;
	destCanvas.height = height;
	destCtx.drawImage(srcCanvas, 0, 0);
}

export function setFrame(frame: number): void {
	currentFrame = frame;
}

export function nextFrame(): void {
	goToFrame(currentFrame + 1);
}
export function previousFrame(): void {
	goToFrame(currentFrame - 1);
}
export function goToFrame(frameNumber: number): void {
	if (frameNumber < 0) return;

	if (frameNumber >= frames.length) {
		addNewFrame();
	}

	copyToSavedFrame(currentFrame);

	setFrame(frameNumber);

	copyFromSavedFrame(frameNumber);
}
